// src/components/Pipeline/Pipeline.jsx

import React, { useState, useRef, useEffect } from 'react';
import Classes from './Pipeline.module.css';
import { useSelector } from 'react-redux';
import MobileVersionPopup from '../../Global-Components/MobileVersionPopup/MobileVersionPopup';
import { AnimatePresence } from 'framer-motion';
import Slider from './Slider';
import AddCompanyPopup from '../../Global-Components/AddCompanyPopup/AddCompanyPopup';
import UpgradeFreePopup from '../../Global-Components/BasicNotifyPopup copy/UpgradeFreePopup';
import Notifications from '../../On-boarding/Global-Components/Notification/Notifcation';
import backend from '../../api';
import AddStagePopup from '../../Global-Components/AddStagePopup/AddStagePopup';

export default function Pipeline() {
  const onBoarding = useSelector((state) => state.companyInfo.info);
  const { user } = useSelector((state) => state.auth);

  const [openNotifications, setOpenNotifications] = useState(false);
  const NotificationsRef = useRef(null);

  // Store all pipelines (stages) in state
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(true);
  const companyId = onBoarding?._id;

  // Fetch all pipelines for this company
  const fetchPipelines = async () => {
    if (!companyId) return;
    setLoading(true);
    try {
      const res = await backend.get(`/pipelines/bycompany?companyId=${companyId}`);
      setPipelines(res.data || []);
    } catch (error) {
      console.error('Error fetching pipelines:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPipelines();
  }, [companyId]);

  // Notification overlay logic
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
        setOpenNotifications(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [openAddCompanyPopup, setOpenAddCompanyPopup] = useState(false);
  const handleCloseAddCompanyPopup = () => setOpenAddCompanyPopup(false);

  const [openAddStagePopup, setOpenAddStagePopup] = useState(false);
  const handleCloseAddStagePopup = () => setOpenAddStagePopup(false);

  useEffect(() => {
    if (onBoarding) {
      document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
    }
  }, [onBoarding]);

  return (
    <>
      <MobileVersionPopup />
      <AnimatePresence>
        {openAddCompanyPopup && <AddCompanyPopup closePopup={handleCloseAddCompanyPopup} />}
      </AnimatePresence>
      <AnimatePresence>
        {openAddStagePopup && <AddStagePopup closePopup={handleCloseAddStagePopup} />}
      </AnimatePresence>
      <AnimatePresence>
        {onBoarding?.subscription === 'free' && <UpgradeFreePopup />}
      </AnimatePresence>

      <div style={{ background: 'white' }}>
        <div className={Classes.banner}>
          <div className={Classes.flexCenter}>{/* potential content */}</div>
          <div className={Classes.flexAlign}>{/* potential content */}</div>
          <div className={Classes.bannerRight}>
            <Notifications />
          </div>
        </div>
        <div className={Classes.mainRightBg}>
          <div className={Classes.hero2}>
            <div className={Classes.searcCon}>
              <img src="/icons/search.png" alt="icon" />
              <input type="text" placeholder="Search..." />
            </div>
            <div className='flexAlign7'>
              <button
                onClick={() => setOpenAddStagePopup(true)}
                className={Classes.addBtn}
                type="button"
              >
                <h5>+ Add stage</h5>
              </button>
              <button
                onClick={() => setOpenAddCompanyPopup(true)}
                className={Classes.addBtn}
                type="button"
              >
                <h5>+ Add company</h5>
              </button>
            </div>
          </div>
          <div className={Classes.hero3}>
            {/* Pass pipelines to Slider */}
            <Slider
              pipelines={pipelines}
              loading={loading}
              setLoading={setLoading}
              refetchPipelines={fetchPipelines}
            />
          </div>
        </div>
      </div>
    </>
  );
}
