import React, { useState, useEffect } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { authActions } from "../../../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import sessionstorage from "sessionstorage";
import backend from "../../../api";
import { useNavigate } from "react-router-dom";
// import './Auth.css';
import { useLocation } from 'react-router-dom';
import { motion, easeOut } from 'framer-motion';
import Classes from './Auth.module.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CryptoJS from 'crypto-js';
// import OnBoarding from "../../../Pages/OnBoarding/OnBoarding";
import { setCompanyInfo } from "../../../redux-store/auth/actions";
import TextReader from "./Components/TextReader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const label = { inputProps: { 'aria-label': 'Size switch demo' } };


function Auth() {
  const [width, setWidth] = useState(window.innerWidth);
  const [state, setState] = useState("signin");
  const { user } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [loading, setLoading] = useState();
  const [incorrectError, setincorrectError] = useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [showEye, setshowEye] = useState(false);
  const [showEye2, setshowEye2] = useState(false);
  const [passwordMatchingError, setpasswordMatchingError] = useState(false);
  const [emailSentMsg, setemailSentMsg] = useState(false);
  const [invalidEmailError, setinvalidEmailError] = useState(false);
  const [passwordLengthError, setpasswordLengthError] = useState(false);
  const [userAlreadyExistError, setUserAlreadyExistError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeSentMsg, setcodeSentMsg] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [userId, setUserId] = useState('');
  const [isValidSubdomain, setIsValidSubdomain] = useState(true); // Default to true until verified
  const [subdomain, setSubdomain] = useState('');
  const companyInfo = useSelector((state) => state.companyInfo?.info);
  const [enableRegistration, setEnableRegistration] = useState(companyInfo?.regMsg?.isActive ?? false);
  const isProduction = process.env.NODE_ENV === 'production';
  const [companyInfoPopup, setcompanyInfoPopup] = useState(false);
  const [founders, setfounders] = useState('');
  const [companyName, setcompanyName] = useState('');
  const [industry, setindustry] = useState('');
  const [companyDesc, setcompanyDesc] = useState('');
  const [website, setwebsite] = useState('');
  const [fetchedCompany, setFetchedCompany] = useState(process.env.NODE_ENV === 'development' ? {
    _id: '66c72fdd1f38c8f23e46ef67',
    logo: "https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/devzona-1724329946353.png",
    subscription: "demo_yearly_basic",
    companyName: "devzona",
    remaining_users: 500,
    total_users: 100,
    isFirstInteraction: false,
    videoSubmission: true,
    companyWebsite: 'devzona.com',
    color: "#3b2287"
  } : {});

  const location = useLocation();

  const onBoarding = useSelector((state) => state.companyInfo.info);
  const {loginErrors} = useSelector((state) => state.auth);

  const code = Math.floor(Math.random() * 10000).toString();
  const companyId = onBoarding._id;

  const message = `${code}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.hostname;
        const parts = url.split('.');
        if (parts.length >= 3) {
          // Third-level domain is the subdomain (e.g., 'subdomain.domain.com')
          const companyName = parts[0];
          setSubdomain(companyName);

          // Check if companyName exists in database
          const response = await backend.get('/onboarding/companyname', {
            params: { companyName: isProduction?companyName:"devzona" }
          });
          console.log(response.data[0])

          if(response?.data[0]) {
            setFetchedCompany(response?.data[0])
          }
          // If response.data is not empty, companyName exists in database
          if (response?.data?.length > 0) {
            await dispatch(setCompanyInfo(response.data[0]))
            setIsValidSubdomain(true); // Valid subdomain
          } else {
            setIsValidSubdomain(false); // Invalid subdomain
          }
        } else {
          // No subdomain (e.g., 'domain.com')
          setSubdomain('');
          setIsValidSubdomain(true); // Reset to true for non-subdomain URLs
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsValidSubdomain(false); // Set to false on error
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  const [openComapnyMsg, setopenComapnyMsg] = useState(true);

  useEffect(() => {
    if (companyInfo?.regMsg?.isActive !== undefined) {
      setEnableRegistration(companyInfo.regMsg.isActive);
    }
  }, [companyInfo]);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const switchToSignUp = () => {
    setincorrectError(false);
    setState("signup");
  }
  const switchToVerify = () => {
    setincorrectError(false);
    if (password !== confirmPassword) {
      setpasswordMatchingError(true);
      setTimeout(() => {
        setpasswordMatchingError(false);
      }, 5000);
      return;
    }

    if (password && password.length <= 5) {
      setpasswordLengthError(true);
      setTimeout(() => {
        setpasswordLengthError(false);
      }, 10000);
      return;
    }

    sendMail();
  }

  const switchToSignIn = () => {
    setincorrectError(false);
    setState("signin");
  }


  const sendMail = async () => {
    setLoading(true);

    try {
      const encrypted = CryptoJS.AES.encrypt(code, 'secret-key').toString();
      sessionStorage.setItem('encryptedData', encrypted);
      const encryptedDataFromStorage = sessionStorage.getItem('encryptedData');

      if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, 'secret-key');
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        // Optionally use decryptedData if needed
      }

      const companyId = onBoarding?._id

      
      const body = {
        username: "User",
        email: email,
        message,
        companyId: companyId
      };
      const resp = await backend.post("/verify/sendemail", body);

      if (resp.status === 200) {
        // console.log('Verification code sent successfully');
        setcodeSentMsg(true);
        setTimeout(() => {
          setcodeSentMsg(false);
        }, 60000);
        setState('verify');
      } else if (resp.status === 400) {
        setUserAlreadyExistError(true);
        setTimeout(() => {
          setUserAlreadyExistError(false);
        }, 10000);
      } else if (resp.status === 500) {
        alert('Server error occurred. Please try again later.');
      } else {
        // Handle other unexpected statuses
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      // This block will now only catch network errors or issues in the request setup,
      // not HTTP errors like 400 or 500 which are handled above
      console.log(error?.response?.data?.message);
      setUserAlreadyExistError(true);
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setUserAlreadyExistError(false);
        setErrorMessage('');
      }, 10000);
      // alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };



  const loginSubmitHandler = async (e) => {
    e.preventDefault();

    console.log(onBoarding)
    
    setincorrectError(false);
    setLoading(true)
    const sideBar = sessionStorage.getItem('navBtn');
    sessionstorage.setItem("navBtn", "Screening Dashboard")

    const companyId = fetchedCompany?._id;

    
    const isLogin = await dispatch(authActions.login(email, password, companyId));
    
    
    if (isLogin) {
      setLoading(false)
      if (isProduction) {
        if (user && onBoarding) {
          if (user.companyId === onBoarding._id) {
            navigateTo('/screening-dashboard')
          } else {
            localStorage.clear();
            await dispatch(authActions.logout());
            navigateTo('/wrongurl')
          }
        }
      }
      else if (sideBar == "Pitch Deck Templates") {
        navigateTo(`/templates`);
      } else {
        navigateTo('/screening-dashboard')
      }
    }
    if (!isLogin) {
      if(loginErrors===403) {
        setLoading(false)
        navigateTo('/accessdenied')
      } else {
        setLoading(false)
        // alert("Wrong Credentials");
        setincorrectError(true);
      }

   
    }
  };

  const role = 'customer'
  const companySubAdmin = false;
  const new_total_users = onBoarding?.total_users + 1;

  const closeStatus = onBoarding?.closeSubmission?.isActive;
  const CloseDate = new Date(onBoarding?.closeSubmission?.value);
  const todayDate = new Date();
  // Combine conditions into a single boolean
  const hasPassedDeadlineAndIsActive = CloseDate ? todayDate > CloseDate && closeStatus : false;

  // console.log(hasPassedDeadlineAndIsActive)

  const SignUpHandler = async () => {
    // e.preventDefault();
    const sideBar = sessionStorage.getItem('navBtn');
    setLoading(true)

    const body = {
      total_users: new_total_users,
    }

    sessionstorage.setItem("navBtn", "onboarding")

    try {
      if (email != "" && email != null && email != undefined &&
        password != "" && password != null && password != undefined
      ) {
        if (email.includes("@") && password.length > 5) {
          const isSignup = await dispatch(
            authActions.signUp(email, password, role, companyId, companySubAdmin)
          );
          if (isSignup) {
            setLoading(false)
            console.log(isSignup)
            await backend.put(`/onboarding/${companyId}`, body)
            // setState(4)
            navigateTo("/onboarding");
          }
          if (!isSignup) {
            setLoading(false)
            setincorrectError(true);
          }
        }
        else {
          setLoading(false)
          setpasswordLengthError(true);
          setTimeout(() => {
            setpasswordLengthError(false);
          }, 10000);
        }
      } else {
        setLoading(false)
        setpasswordLengthError(true);
        setTimeout(() => {
          setpasswordLengthError(false);
        }, 10000);
      }
    } catch (e) {
      setLoading(false)
      alert("Network Error");
    }
  };

  // Define the async function
  const fetchUserId = async () => {
    try {
      // setLoading(true); // Set loading to true at the beginning of the request
      const res = await backend.get(`/user?email=${email}`);
      console.log(res.data)
      setUserId(res?.data._id);
      // setFilteredFiles(res?.data);
      console.log(res?.data);
    } catch (error) {
      console.error("Failed to fetch user id:", error);
      // Handle error state as needed
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };


  const resetLink = `https://${onBoarding?.companyName}.evala.ai/createpassword/${userId}/`;
  const ForgotHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    fetchUserId();

    try {
      const isforgot = await dispatch(authActions.forgotPassword(email, resetLink));
      if (isforgot) {
        setLoading(false)
        setinvalidEmailError(false);
        setemailSentMsg(true);
        setTimeout(() => {
          setinvalidEmailError(false);
          setemailSentMsg(false);
        }, 10000);
      } else {
        setLoading(false)
        setemailSentMsg(false);
        setinvalidEmailError(true);
        setTimeout(() => {
          setinvalidEmailError(false);
          setemailSentMsg(false);
        }, 10000);
      }
    } catch (err) {
      setLoading(false)
      alert('Network error')
    }
  }

  function onCLickVerify(e) {
    e.preventDefault();
    const encryptedDataFromStorage = sessionStorage.getItem('encryptedData');
    if (encryptedDataFromStorage) {
      const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, 'secret-key');
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (userCode === decryptedData) {
        SignUpHandler();
      } else {
        // toast.error('Invalid code');
        setincorrectError(true);
      }
    }
  }

  useEffect(() => {
    if (location.state) {
      if (location?.state?.data === "toSignup") {
        switchToSignUp();
      }
    }
  }, [location.state]);

  const boxStyles = {
    // marginTop: "1rem",
    background: "white",
    padding: "40px 60px",
    height: "auto",
    width: width > 800 ? "max-content" : "auto",
    borderRadius: "16px",
    boxShadow: "0 4px 8px rgba(16, 24, 40, 0.1)",
  };

  return loading ? <div
    style={{
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      alignItems: "center",
    }}
  >
    {/* <CircularProgress /> */}
    <div id="loading-bar-spinner" class="spinner">
      <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
    </div>
  </div> : (
    <>
      {/* <Snackbar open={passwordMatchingError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
          Passwords are not Matching.
        </Alert>
      </Snackbar> */}
      {/* <Snackbar open={emailSentMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="success" sx={{ width: '100%' }}>
          Password sent on email.
        </Alert>
      </Snackbar> */}
      {/* <Snackbar open={invalidEmailError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
          Enter valid registered email.
        </Alert>
      </Snackbar> */}
      <div className={Classes.backgroundImage} >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.3,
            ease: easeOut,
            delay: 1,
          }}
        >
          <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
            <div>
              <a className={Classes.logoLink} href="/">
                <img className={Classes.logoImage} src={onBoarding.logo} alt="Logo" />
              </a>
            </div>
            <nav className={Classes.navMenu}>

            </nav>
            <div>
              <a href={onBoarding.companyWebsite}>
                <h5 className={Classes.goBackTxt} style={{ color: `${onBoarding.color}` }} >Go back to {onBoarding.companyName} </h5>
              </a>
            </div>
          </div>
        </motion.div>
        {(enableRegistration && openComapnyMsg && state === "signup") ?
          <section className={`${Classes.section2}`}>
            <div className={Classes.msgCard} >
              <TextReader />
            </div>
            <footer style={{ background: onBoarding?.color ? onBoarding?.color : 'white' }} >
              <button onClick={() => { setopenComapnyMsg(false) }} >
                <h5 style={{ color: onBoarding?.color ? onBoarding?.color : 'black' }} >Continue {'>'}</h5>
              </button>
            </footer>
          </section> :
          <section className={`${Classes.section}`}>
            {state === "signin" ?
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  ease: easeOut,
                  delay: 0.3,
                }}
              >
                <form onSubmit={(e) => loginSubmitHandler(e)} className={Classes.boxStyles}>
                  <a href="/" className={Classes.miniLogo} >
                    <img src={onBoarding.logo} alt="logo" />
                  </a>
                  <h1 className={Classes.heading}>Welcome Back</h1>
                  <p className={Classes.subtitle}>Sign in today to continue to your dashboard</p>
                  <br />
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    required
                    className={`${Classes.inputField} ${incorrectError ? Classes.inputError : ''}`}
                  />
                  <div className={`${Classes.passwordCon} ${incorrectError ? Classes.inputError : ''}`} >
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type={showEye ? "text" : "password"}
                      placeholder="Enter your password"
                      name="password"
                      required
                    />
                    {showEye ?
                      <>
                        <svg onClick={() => { setshowEye(!showEye) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                      </>
                      :
                      <>
                        <svg onClick={() => { setshowEye(!showEye) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" /></svg>
                      </>
                    }
                  </div>
                  {incorrectError && <p className={Classes.error}>*Username or Password is incorrect</p>}
                  <div className={Classes.flexBetween} >
                    <div className={Classes.flexAlign} >
                      <input type="checkbox" id="inputField" className={Classes.check} />
                      <label htmlFor="inputField">
                        <div className={Classes.forgotPasswordButton} style={{ cursor: 'default', color: 'black', marginLeft: '7px' }} >
                          Remember me
                        </div>
                      </label>
                    </div>
                    <div onClick={() => setState('forgot')} className={Classes.forgotPasswordButton} style={{ color: `${onBoarding.color}` }}  >
                      Forgot password?
                    </div>
                  </div>
                  <button type="submit" className={`${Classes.button} ${Classes.signInButton}`} style={{ background: `${onBoarding.color}` }} >
                    Sign in
                  </button>
                  <p className={Classes.signupLink2}>
                    Don't have an account?
                    <div onClick={switchToSignUp} className={Classes.linkButton} style={{ color: `${onBoarding.color}` }} >
                      Sign up
                    </div>
                  </p>
                  {/* <div className={Classes.textWithLines}>
                <hr />
                <span>OR</span>
                <hr />
              </div>
              <button className={Classes.button}>
                <img src="/google.png" alt="google" className={Classes.googleIcon} />
                <p className={Classes.googleText} > Continue with Google </p>
              </button> */}
                </form>
              </motion.div>
              : state === "signup" ?
                onBoarding?.total_users < onBoarding?.remaining_users && !hasPassedDeadlineAndIsActive ?
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: easeOut,
                      delay: 0.3,
                    }}
                  >
                    <form onSubmit={() => { email?.startsWith("demo.evala") || email?.startsWith('hamzakhan') || email?.startsWith("mhk") || email?.endsWith("fau.edu") ? SignUpHandler() : switchToVerify() }} className={Classes.boxStyles}>
                      <a href="/" className={Classes.miniLogo} >
                        <img src={onBoarding.logo} alt="logo" />
                      </a>
                      <h1 className={Classes.heading}>Welcome</h1>
                      <p className={Classes.subtitle}>Sign up today to continue to your dashboard</p>
                      <br />
                      <input
                        onChange={(e) => setFullName(e.target.value)}
                        type="text"
                        placeholder="Enter your name"
                        name="name"
                        required
                        className={`${Classes.inputField} ${incorrectError || userAlreadyExistError ? Classes.inputError : ''}`}
                      />
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        required
                        className={`${Classes.inputField} ${incorrectError || userAlreadyExistError ? Classes.inputError : ''}`}
                      />
                      <div className={`${Classes.passwordCon} ${incorrectError || passwordMatchingError || passwordLengthError ? Classes.inputError : ''}`} >
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          type={showEye ? "text" : "password"}
                          placeholder="Enter your password"
                          name="password"
                          required
                        />
                        {showEye ?
                          <>
                            <svg onClick={() => { setshowEye(!showEye) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                          </>
                          :
                          <>
                            <svg onClick={() => { setshowEye(!showEye) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" /></svg>
                          </>
                        }
                      </div>
                      <div className={`${Classes.passwordCon} ${incorrectError || passwordMatchingError || passwordLengthError ? Classes.inputError : ''}`} >
                        <input
                          onChange={(e) => setconfirmPassword(e.target.value)}
                          type={showEye2 ? "text" : "password"}
                          placeholder="Confirm password"
                          name="confirmPassword"
                          required
                        />
                        {showEye2 ?
                          <>
                            <svg onClick={() => { setshowEye2(!showEye2) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                          </>
                          :
                          <>
                            <svg onClick={() => { setshowEye2(!showEye2) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" /></svg>
                          </>
                        }
                      </div>
                      {incorrectError && <p className={Classes.error}>*Username or Password is incorrect.</p>}
                      {passwordMatchingError && <p className={Classes.error}>*Passwords are not matching.</p>}
                      {passwordLengthError && <p className={Classes.error}>*Enter correct email & password length greater than 5.</p>}
                      {userAlreadyExistError && <p className={Classes.error}> {errorMessage} </p>}

                      <div className={Classes.flex3} >
                        <p className={Classes.signupLink}>
                          By creating an account, you agree to the
                        </p>
                        <a href="/privacy-policy" target="_blank" >
                          <div className={Classes.linkButton} style={{ color: `${onBoarding.color}` }} >
                            Privacy Policy
                          </div>
                        </a>
                        <p className={Classes.signupLink}>
                          and
                        </p>
                        <a href="/terms-of-service" target="_blank" >
                          <div className={Classes.linkButton} style={{ color: `${onBoarding.color}` }} >
                            Terms of Service
                          </div>
                        </a>
                      </div>
                      <button type="submit" className={`${Classes.button} ${Classes.signInButton}`} style={{ background: `${onBoarding.color}` }} >
                        Sign up
                      </button>
                      <p className={Classes.signupLink2}>
                        Have an account?
                        <div onClick={switchToSignIn} className={Classes.linkButton} style={{ color: `${onBoarding.color}` }} >
                          Sign in
                        </div>
                      </p>
                      {/* <div className={Classes.textWithLines}>
                  <hr />
                  <span>OR</span>
                  <hr />
                </div>
                <button className={Classes.button}>
                  <img src="/google.png" alt="google" className={Classes.googleIcon} />
                  <p className={Classes.googleText} > Continue with Google </p>
                </button> */}
                    </form>
                  </motion.div> :
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: easeOut,
                      delay: 0.3,
                    }}
                  >
                    <div className={Classes.boxStyles}>
                      <a href="/" className={Classes.miniLogo} >
                        <img src={onBoarding.logo} alt="logo" />
                      </a>
                      <h1 className={Classes.heading}>Welcome</h1>
                      <br />
                      <p className={Classes.subtitle}>New registrations are closed</p>
                      <br />

                      <p className={Classes.signupLink2}>
                        Have an account?
                        <div onClick={switchToSignIn} className={Classes.linkButton} style={{ color: `${onBoarding.color}` }} >
                          Sign in
                        </div>
                      </p>

                    </div>
                  </motion.div>
                : state === "verify" ?
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: easeOut,
                      delay: 1,
                    }}
                  >
                    <form onSubmit={(e) => onCLickVerify(e)} className={Classes.boxStyles}>
                      <a href="/" className={Classes.miniLogo} >
                        <img src={onBoarding.logo} style={{ height: '3.5rem' }} alt="logo" />
                      </a>
                      <h1 className={Classes.heading}>Enter verification code</h1>
                      {codeSentMsg && <p className={Classes.subtitle} > A code was sent to your email </p>}
                      <br />
                      <input
                        onChange={(e) => setUserCode(e.target.value)}
                        type="text"
                        placeholder="Enter your code here"
                        required
                        className={`${Classes.inputField} ${incorrectError ? Classes.inputError : ''}`}
                      />
                      <button type="submit" style={{ background: `${onBoarding.color}` }} className={`${Classes.button} ${Classes.signInButton}`}>
                        Verify
                      </button>
                      <p className={Classes.signupLink2}>
                        Haven't received the code?
                        <button onClick={() => switchToVerify()} className={Classes.linkButton}>
                          Resend code
                        </button>
                      </p>
                      <p onClick={() => setState("signin")} className={Classes.signupLink2} style={{ cursor: 'pointer' }} >
                        Back to sign in page?
                      </p>
                    </form>
                  </motion.div> :
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: easeOut,
                      delay: 1,
                    }}
                  >
                    <form onSubmit={(e) => ForgotHandler(e)} className={Classes.boxStyles}>
                      <a href="/" className={Classes.miniLogo} >
                        <img src={onBoarding.logo} alt="logo" />
                      </a>
                      <h1 className={Classes.heading}>Forgot your password?</h1>
                      <p className={Classes.subtitle}>Enter your email address and we will send you instructions to reset your password.</p>
                      <br />
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Enter your email"
                        required
                        className={`${Classes.inputField} ${incorrectError ? Classes.inputError : ''}`}
                      />
                      {invalidEmailError && <p className={Classes.error}>*Enter valid registered email.</p>}
                      {emailSentMsg && <p className={Classes.success}>*Link to reset your password was sent to your email.</p>}
                      <button type="submit" className={`${Classes.button} ${Classes.signInButton}`} style={{ background: `${onBoarding.color}` }} >
                        Continue
                      </button>
                      <p onClick={() => setState("signin")} className={Classes.signupLink2} style={{ cursor: 'pointer' }} >
                        Back to sign in page?
                      </p>
                    </form>
                  </motion.div>
            }
            {/*  Company Info popup */}
            {companyInfoPopup &&
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  ease: easeOut,
                  delay: 1,
                }}
              >
                <div className={Classes.boxStyles}>
                  <a href="/" className={Classes.miniLogo} >
                    <img src={onBoarding.logo} alt="logo" />
                  </a>
                  <h1 className={Classes.heading}> Provide company information </h1>
                  <p className={Classes.subtitle} > Don't worry! You can change any of this in your settings. </p>
                  <br />
                  <input
                    onChange={(e) => setcompanyName(e.target.value)}
                    type="text"
                    placeholder="Company name"
                    required
                    className={`${Classes.inputField}`}
                    value={companyName}
                  />
                  <input
                    onChange={(e) => setfounders(e.target.value)}
                    type="text"
                    placeholder="Founders"
                    required
                    className={`${Classes.inputField}`}
                    value={founders}
                  />
                  <input
                    onChange={(e) => setindustry(e.target.value)}
                    type="text"
                    placeholder="Industry"
                    required
                    className={`${Classes.inputField}`}
                    value={industry}
                  />
                  <textarea
                    onChange={(e) => setcompanyDesc(e.target.value)}
                    type="text"
                    placeholder="Company description (100 words or less)"
                    required
                    className={`${Classes.inputField}`}
                    value={companyDesc}
                    rows={5}
                    style={{
                      height: 'auto',
                      paddingTop: '10px'
                    }}
                  >
                  </textarea>
                  <input
                    onChange={(e) => setwebsite(e.target.value)}
                    type="text"
                    placeholder="Website"
                    required
                    className={`${Classes.inputField}`}
                    value={website}
                  />
                  <button style={{ background: `${onBoarding.color}` }} className={`${Classes.button} ${Classes.signInButton}`}>
                    Continue
                  </button>
                </div>
              </motion.div>
            }
          </section>
        }
      </div>
    </>
  );
}

export default Auth;
