import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Classes from '../styles/style.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import Checkbox from '@mui/material/Checkbox';

export default function IndustryDropDown({ selected, onChange }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const industryOptions = [
        { value: 'SaaS', label: 'SaaS' },
        { value: 'MedTech', label: 'MedTech' },
        { value: 'EdTech', label: 'EdTech' },
        { value: 'FinTech', label: 'FinTech' },
        { value: 'FoodTech', label: 'FoodTech' },
        { value: 'E-Commerce', label: 'E-Commerce' },
        { value: 'HealthTech', label: 'HealthTech' },
        { value: 'ArgrTech', label: 'ArgrTech' },
        { value: 'CleanTech', label: 'CleanTech' },
        { value: 'InsurTech', label: 'InsurTech' },
        { value: 'TravelTech', label: 'TravelTech' },
        { value: 'Gaming', label: 'Gaming' },
        { value: 'Entertainment', label: 'Entertainment' },
        { value: 'AI/ML', label: 'AI/ML' },
        { value: 'Cybersecurity', label: 'Cybersecurity' },
        { value: 'Blockchain', label: 'Blockchain' },
        { value: 'IoT', label: 'IoT' },
        { value: 'RetailTech', label: 'RetailTech' },
        { value: 'SocialTech', label: 'SocialTech' },
        { value: 'LegalTech', label: 'LegalTech' },
        { value: 'AR', label: 'AR' },
        { value: 'WearableTechnology', label: 'Wearable Technology' },
        { value: 'Other', label: 'Other' },
    ];

    const [dropDown, setDropDown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    // State for storing the user's search term
    const [searchTerm, setSearchTerm] = useState('');

    // Filtered industries based on searchTerm
    const filteredIndustryOptions = industryOptions.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Set custom CSS property from onBoarding (if needed)
    useEffect(() => {
        if (onBoarding) {
            document.documentElement.style.setProperty(
                '--dropdown-border',
                onBoarding.color || 'transparent'
            );
        }
    }, [onBoarding]);

    // Close dropdown if clicked outside
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle selecting/unselecting an industry
    const handleSelect = (option) => {
        let newSelection;
        if (selected.includes(option.value)) {
            // Remove existing selection
            newSelection = selected.filter((item) => item !== option.value);
        } else {
            // Single selection (replace any existing selections)
            // If you want multiple selections, do: newSelection = [...selected, option.value];
            newSelection = [option.value];
        }
        onChange(newSelection);
    };

    return (
        <div className={Classes.dropDown} ref={dropdownRef} style={{ margin: '0' }} >
            {/* Dropdown Head */}
            <div
                className={Classes.SelectinputField}
                onClick={() => setDropDown(!dropDown)}
            >
                <p style={{ textTransform: 'capitalize' }}>Industry</p>
                <img
                    src="/onBoarding/arrow-down.svg"
                    alt="delete"
                    style={{
                        height: '10px',
                        transform: dropDown ? 'rotate(180deg)' : '',
                    }}
                />
            </div>

            {/* Dropdown Body */}
            <AnimatePresence>
                {dropDown && (
                    <motion.div
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -5 }}
                        transition={{ duration: 0.15 }}
                        className={Classes.dropDownBody}
                    >
                        <div className={Classes.ddPointerCon}>
                            <div className={Classes.dropDownPointer}></div>
                        </div>

                        <div className={Classes.dropDownBodyInner}>
                            <div>
                                {/* Search Box */}
                                <div className={Classes.searcCon}>
                                    <input
                                        placeholder="Search Industries"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>

                                {/* Mapped (Filtered) Industries */}
                                {filteredIndustryOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className={`${Classes.dropDownElem} ${selected.includes(option.value)
                                            ? Classes.activeDropElem
                                            : ''
                                            }`}
                                    >
                                        <label htmlFor={index}>
                                            <h5>{option.label}</h5>
                                        </label>
                                        <Checkbox
                                            checked={selected.includes(option.value)}
                                            onChange={() => handleSelect(option)}
                                            id={index.toString()}
                                            sx={{
                                                color: onBoarding.color,
                                                padding: '5px',
                                                '&.Mui-checked': {
                                                    color: onBoarding.color,
                                                },
                                                '& .MuiSvgIcon-root': { fontSize: 17 },
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <input type="hidden" aria-label="Without label" readOnly />
        </div>
    );
}
