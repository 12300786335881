import React, { useState, useEffect } from 'react';
import Classes from './AddCompanyPopup.module.css';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AnimatePresence, motion } from 'framer-motion';
import { usePreventScrolling } from '../../Hooks/usePreventScrolling';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateDeckInfo } from '../../redux-store/auth/actions';
import backend from '../../api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSvg = (props) => (
  <svg
    version="1.1"
    id="fi_179386"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 286.054 286.054"
    className="custom-svg"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
        c78.996,0,143.027-64.022,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236
        c-64.183,0-116.209-52.026-116.209-116.209S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209
        S207.21,259.236,143.027,259.236z M143.036,62.726c-10.244,0-17.995,5.346-17.995,13.981v79.201c0,8.644,7.75,13.972,17.995,13.972
        c9.994,0,17.995-5.551,17.995-13.972V76.707C161.03,68.277,153.03,62.726,143.036,62.726z M143.036,187.723
        c-9.842,0-17.852,8.01-17.852,17.86c0,9.833,8.01,17.843,17.852,17.843s17.843-8.01,17.843-17.843
        C160.878,195.732,152.878,187.723,143.036,187.723z"
      />
    </g>
  </svg>
);

function AddCompanyPopup({ closePopup }) {
  const onBoarding = useSelector((state) => state.companyInfo.info);
  const deckUpdate = useSelector((state) => state.deck.data);
  const [deckData, setDeckData] = useState([]);
  const [filteredDecks, setFilteredDecks] = useState([]);
  const [selectedDecks, setSelectedDecks] = useState([]);

  // We fetch all pipelines so the user can choose from them
  const [pipelines, setPipelines] = useState([]);
  // The pipeline name the user selects from the dropdown
  const [selectedPipelineName, setSelectedPipelineName] = useState('');

  // We'll store the decks that are already in the selected pipeline, to avoid overwriting them
  const [existingDecksInSelectedPipeline, setExistingDecksInSelectedPipeline] = useState([]);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const dispatch = useDispatch();
  const companyId = onBoarding?._id;

  usePreventScrolling();

  // 1) fetch all pipelines
  useEffect(() => {
    const fetchPipelines = async () => {
      if (!companyId) return;
      try {
        const pipelineRes = await backend.get(`/pipelines/bycompany?companyId=${companyId}`);
        setPipelines(pipelineRes.data || []);
      } catch (err) {
        console.error('Error fetching pipelines list:', err);
      }
    };
    fetchPipelines();
  }, [companyId]);

  // 2) fetch available decks
  useEffect(() => {
    const fetchData = async () => {
      if (!companyId) return;

      try {
        // fetch all pipelines
        const pipelineResponse = await backend.get(`/pipelines/bycompany?companyId=${companyId}`);
        const pipelinesData = pipelineResponse?.data || [];

        // gather all deck IDs from all pipelines
        const pipelineDeckIds = pipelinesData.reduce((acc, pipeline) => {
          return acc.concat(pipeline.decks.map((d) => d.deck._id.toString()));
        }, []);

        // fetch all decks for this company
        const deckResponse = await backend.get(`/deck/bycompany?companyId=${companyId}`);
        const allDecks = deckResponse?.data || [];

        // filter out adminDelete, adminDeck, not submitted
        const filteredData = allDecks.filter(
          (item) =>
            !item?.adminDelete && 
            !item?.adminDeck &&
            item?.status === 'submitted'
        );
        // exclude ones already in a pipeline
        const availableDecks = filteredData.filter(
          (d) => !pipelineDeckIds.includes(d._id.toString())
        );

        setDeckData(availableDecks);
        setFilteredDecks(availableDecks);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbar({
          open: true,
          message: 'Error fetching data',
          severity: 'error',
        });
      }
    };

    fetchData();
  }, [companyId, deckUpdate]);

  // 3) Whenever the user picks a pipeline from the dropdown,
  //    find its existing deck IDs so we can keep them
  useEffect(() => {
    if (!selectedPipelineName || !pipelines.length) {
      setExistingDecksInSelectedPipeline([]);
      return;
    }
    // find the pipeline object
    const pipelineObj = pipelines.find((p) => p.pipelineName === selectedPipelineName);
    if (pipelineObj) {
      // gather the deck IDs
      const existing = pipelineObj.decks.map((sub) => sub.deck._id.toString());
      setExistingDecksInSelectedPipeline(existing);
    } else {
      // pipeline not found, maybe user typed a new name
      setExistingDecksInSelectedPipeline([]);
    }
  }, [selectedPipelineName, pipelines]);

  const handleBgClick = () => {
    closePopup();
  };

  const handleCardClick = (event) => {
    event.stopPropagation();
  };

  // checkbox logic
  const handleCheckboxChange = (deckId) => {
    setSelectedDecks((prev) => {
      if (prev.includes(deckId)) {
        return prev.filter((id) => id !== deckId);
      } else {
        return [...prev, deckId];
      }
    });
  };

  // final array of decks = existing in pipeline + newly selected
  const combinedDecks = Array.from(
    new Set([...existingDecksInSelectedPipeline, ...selectedDecks])
  );

  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedPipelineName) {
        setSnackbar({
          open: true,
          message: 'Please select a pipeline name',
          severity: 'error',
        });
        return;
      }

      const response = await backend.put('/pipelines/upsert', {
        pipelineName: selectedPipelineName,
        companyId,
        decks: combinedDecks,
      });

      if (response.status === 200 || response.status === 201) {
        await dispatch(updateDeckInfo(response?.data));
        setSnackbar({
          open: true,
          message: 'Pipeline updated successfully!',
          severity: 'success',
        });
        closePopup();
      }
    } catch (error) {
      console.error('Error submitting pipeline:', error);
      setSnackbar({ open: true, message: 'Error submitting pipeline', severity: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={Classes.popup}
        onClick={handleBgClick}
      >
        <motion.div
          initial={{ y: 7 }}
          animate={{ y: 0 }}
          exit={{ y: 7 }}
          transition={{ duration: 0.25 }}
          className={Classes.card}
          onClick={handleCardClick}
        >
          <div className={Classes.close} onClick={closePopup}>
            <svg
              height="20px"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </div>
          <div
            className={Classes.bgPopup}
            style={{ background: onBoarding?.color }}
          >
            <div></div>
            <h5 className={Classes.title2}>Add Company</h5>
            <div className={Classes.curveCon}></div>
          </div>
          <form onSubmit={handleConfirmSubmit}>
            <div className={Classes.cardCon}>
              <h5 className={Classes.title}>
                Select companies to add to your pipeline, and choose a pipeline.
              </h5>

              {/* 1) Pipeline dropdown */}
              <div className={Classes.dropdownRow}>
                <label>
                  <h5 className={Classes.text2}>Choose pipeline:</h5>
                </label>
                <select
                  className={Classes.input}
                  value={selectedPipelineName}
                  onChange={(e) => setSelectedPipelineName(e.target.value)}
                >
                  <option value="">-- Select Pipeline --</option>
                  {pipelines.map((p) => (
                    <option key={p._id} value={p.pipelineName}>
                      {p.pipelineName}
                    </option>
                  ))}
                  {/* if you want user to create a new pipeline name, you could add:
                      <option value="new_pipeline">Create New Pipeline</option>
                    or let them type a new name in an <input> 
                  */}
                </select>
              </div>

              <div className={Classes.selectionCon}>
                {filteredDecks.length > 0 ? (
                  filteredDecks.map((deck) => (
                    <div className={Classes.selectionElem} key={deck._id}>
                      <label>
                        <h5 className={Classes.text2}>
                          {deck?.userId?.companyName || 'Unknown user'}
                        </h5>
                      </label>
                      <Checkbox
                        checked={selectedDecks.includes(deck._id)}
                        onChange={() => handleCheckboxChange(deck._id)}
                        sx={{
                          color: onBoarding.color,
                          padding: '5px',
                          '&.Mui-checked': {
                            color: onBoarding.color,
                          },
                          '& .MuiSvgIcon-root': { fontSize: 17 },
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <label>
                    <h5 className={Classes.text2}>No companies available</h5>
                  </label>
                )}
              </div>

              <div className={Classes.flexAlign}>
                <button
                  type="submit"
                  className={Classes.btn2}
                  style={{
                    background: onBoarding.color ? onBoarding.color : 'black',
                    border: `1px solid ${onBoarding.color}`,
                  }}
                >
                  <h5>Submit</h5>
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </motion.div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddCompanyPopup;
