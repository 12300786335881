// src/Global-Components/AddStagePopup/AddStagePopup.jsx

import React, { useState, useEffect } from 'react';
import Classes from './AddStagePopup.module.css';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AnimatePresence, motion } from 'framer-motion';
import { Checkbox } from '@mui/material';
import { usePreventScrolling } from '../../Hooks/usePreventScrolling';
import { updateDeckInfo } from '../../redux-store/auth/actions';
import backend from '../../api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddStagePopup({ closePopup }) {
  const onBoarding = useSelector((state) => state.companyInfo.info);
  const deckUpdate = useSelector((state) => state.deck.data);
  const companyId = onBoarding?._id;
  const dispatch = useDispatch();

  usePreventScrolling();

  // Local state for available decks (those not yet assigned to any stage)
  const [deckData, setDeckData] = useState([]);
  const [filteredDecks, setFilteredDecks] = useState([]);
  const [selectedDecks, setSelectedDecks] = useState([]);

  // Since we now want the user to add a stage manually,
  // we use a controlled input for the stage title.
  const [stageTitle, setStageTitle] = useState('');

  // We still fetch all pipelines so that we can
  // – optionally – determine which decks are already assigned.
  const [pipelines, setPipelines] = useState([]);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // 1) Fetch all pipelines for this company
  useEffect(() => {
    const fetchPipelines = async () => {
      if (!companyId) return;
      try {
        const pipelineRes = await backend.get(`/pipelines/bycompany?companyId=${companyId}`);
        setPipelines(pipelineRes.data || []);
      } catch (err) {
        console.error('Error fetching pipelines list:', err);
      }
    };
    fetchPipelines();
  }, [companyId]);

  // 2) Fetch available decks (decks not already in any pipeline)
  useEffect(() => {
    const fetchData = async () => {
      if (!companyId) return;
      try {
        // Fetch all pipelines (to know which decks are already assigned)
        const pipelineResponse = await backend.get(`/pipelines/bycompany?companyId=${companyId}`);
        const pipelinesData = pipelineResponse?.data || [];
        const pipelineDeckIds = pipelinesData.reduce((acc, pipeline) => {
          return acc.concat(pipeline.decks.map((d) => d.deck._id.toString()));
        }, []);

        // Fetch all decks for this company
        const deckResponse = await backend.get(`/deck/bycompany?companyId=${companyId}`);
        const allDecks = deckResponse?.data || [];

        // Filter out decks that are marked for deletion or are not yet submitted
        const filteredData = allDecks.filter(
          (item) =>
            !item?.adminDelete &&
            !item?.adminDeck &&
            item?.status === 'submitted'
        );

        // Only include decks that are not already in any pipeline
        const availableDecks = filteredData.filter(
          (d) => !pipelineDeckIds.includes(d._id.toString())
        );

        setDeckData(availableDecks);
        setFilteredDecks(availableDecks);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbar({
          open: true,
          message: 'Error fetching data',
          severity: 'error',
        });
      }
    };

    fetchData();
  }, [companyId, deckUpdate]);

  // Checkbox logic for selecting decks
  const handleCheckboxChange = (deckId) => {
    setSelectedDecks((prev) => {
      if (prev.includes(deckId)) {
        return prev.filter((id) => id !== deckId);
      } else {
        return [...prev, deckId];
      }
    });
  };

  // The final decks array is simply the selected decks.
  // (Since there is no “existing” stage yet, no decks are preset.)
  const combinedDecks = Array.from(new Set(selectedDecks));

  // Handle form submission: upsert (create) the stage using PUT /pipelines/upsert
  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    if (!stageTitle.trim()) {
      setSnackbar({
        open: true,
        message: 'Please enter a title for the stage',
        severity: 'error',
      });
      return;
    }
    try {
      const response = await backend.put('/pipelines/upsert', {
        pipelineName: stageTitle.trim(),
        companyId,
        decks: combinedDecks,
      });

      if (response.status === 200 || response.status === 201) {
        await dispatch(updateDeckInfo(response?.data));
        setSnackbar({
          open: true,
          message: 'Stage created successfully!',
          severity: 'success',
        });
        closePopup();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error submitting stage:', error);
      setSnackbar({ open: true, message: 'Error submitting stage', severity: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Prevent closing the popup if the background is clicked
  const handleBgClick = () => {
    closePopup();
  };

  const handleCardClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={Classes.popup}
        onClick={handleBgClick}
      >
        <motion.div
          initial={{ y: 7 }}
          animate={{ y: 0 }}
          exit={{ y: 7 }}
          transition={{ duration: 0.25 }}
          className={Classes.card}
          onClick={handleCardClick}
        >
          <div className={Classes.close} onClick={closePopup}>
            <svg
              height="20px"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </div>
          <div
            className={Classes.bgPopup}
            style={{ background: onBoarding?.color }}
          >
            <div></div>
            <h5 className={Classes.title2}>Add Stage</h5>
            <div className={Classes.curveCon}></div>
          </div>
          <form onSubmit={handleConfirmSubmit}>
            <div className={Classes.cardCon}>
              <h5 className={Classes.title}>
                Add a new stage to the pipeline.
              </h5>

              {/* Input for stage title */}
              <div className={Classes.searcCon}>
                <input 
                  placeholder="Title of stage"
                  value={stageTitle}
                  onChange={(e) => setStageTitle(e.target.value)}
                />
              </div>

              {/* Optionally render the available decks list with checkboxes */}
              {filteredDecks.length > 0 && (
                <div className={Classes.decksList}>
                  <h5>Select decks to add to this stage:</h5>
                  {filteredDecks.map((deck) => (
                    <div key={deck._id} className={Classes.deckItem}>
                      <Checkbox
                        checked={selectedDecks.includes(deck._id)}
                        onChange={() => handleCheckboxChange(deck._id)}
                      />
                      <span>{deck?.userId?.companyName || deck.title || 'Untitled Deck'}</span>
                    </div>
                  ))}
                </div>
              )}

              <div className={Classes.flexAlign}>
                <button
                  type="submit"
                  className={Classes.btn2}
                  style={{
                    background: onBoarding?.color ? onBoarding?.color : 'black',
                    border: `1px solid ${onBoarding?.color}`,
                  }}
                >
                  <h5>Submit</h5>
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </motion.div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddStagePopup;
