// src/components/Pipeline/Slider.jsx

import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Classes from './Pipeline.module.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import SeeMorePopup from '../../Global-Components/SeeMorePopup/SeeMorePopup';
import PipelineCard from './Components/PipelineCard/PipelineCard';
import ContentLoader from 'react-content-loader';
import { AnimatePresence } from 'framer-motion';
import backend from '../../api';
import StageMenu from './Components/PipelineCard/StageMenu';

// Icons (Assuming these are defined within Slider.jsx)
const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" cursor="pointer" height="16px" width="16px" viewBox="0 0 512 512">
    {/* SVG Path */}
    <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z" />
  </svg>
);

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" cursor="pointer" height="17px" width="17px" viewBox="0 0 384 512">
    {/* SVG Path */}
    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
  </svg>
);

const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" cursor="pointer" height="17px" width="17px" viewBox="0 0 448 512">
    {/* SVG Path */}
    <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-242.7c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32L64 32zm0 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 224c-17.7 0-32-14.3-32-32l0-64zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
  </svg>
);

// Simple ContentLoader for skeleton
const SmallRowSkeleton = (props) => (
  <div style={{ width: props.width, display: 'flex', alignItems: 'center' }}>
    <ContentLoader
      speed={1.2}
      width="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="15" ry="15" width="100%" {...props} />
    </ContentLoader>
  </div>
);

export default function Slider({
  pipelines,
  loading,
  setLoading,
  refetchPipelines
}) {
  const onBoarding = useSelector((state) => state.companyInfo.info);

  const [openSeeMorePopup, setopenSeeMorePopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [decisionLoading, setdecisionLoading] = useState(false);

  // Local rename states: map pipeline._id to edit state
  const [pipelineEdits, setPipelineEdits] = useState({});

  // Initialize editing states based on fetched pipelines
  useEffect(() => {
    const newEdits = {};
    pipelines.forEach((pipeline) => {
      newEdits[pipeline._id] = {
        isEditing: false,
        title: pipeline.pipelineName,
      };
    });
    setPipelineEdits(newEdits);
  }, [pipelines]);

  const handleCloseSeeMore = () => setopenSeeMorePopup(false);

  // Rename pipeline in DB and then re-fetch
  const handleSaveTitle = async (pipelineId, newName) => {
    try {
      setLoading(true);
      const pipelineObj = pipelines.find((p) => p._id === pipelineId);
      if (!pipelineObj) {
        console.warn(`No pipeline found with ID [${pipelineId}]`);
        return;
      }
      await backend.put(`/pipelines/${pipelineObj._id}`, {
        pipelineName: newName,
      });
      console.log(`Renamed pipeline "${pipelineObj.pipelineName}" -> "${newName}".`);

      // After renaming, re-fetch from the server to get updated name
      if (refetchPipelines) {
        await refetchPipelines();
      }
    } catch (err) {
      console.error('Error renaming pipeline:', err);
    } finally {
      setLoading(false);
    }
  };

  // Get decks for a pipeline by pipeline ID
  const getDecksFor = (pipelineId) => {
    const pipelineObj = pipelines.find((p) => p._id === pipelineId);
    return pipelineObj ? pipelineObj.decks : [];
  };

  return (
    <>
      <AnimatePresence>
        {openSeeMorePopup && (
          <SeeMorePopup
            closePopup={handleCloseSeeMore}
            deck={popupData}
            setdecisionLoading={setdecisionLoading}
          />
        )}
      </AnimatePresence>

      {loading ? (
        <Swiper
          slidesPerView={3}
          spaceBetween={15}
          freeMode
          pagination={{ clickable: true }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {/* Render skeletons based on the number of pipelines */}
          {pipelines.length > 0 ? pipelines.map((_, i) => (
            <SwiperSlide key={i} className={Classes.swiperElem}>
              <SmallRowSkeleton height="89vh" width="22rem" />
            </SwiperSlide>
          )) : [...Array(5)].map((_, i) => (
            <SwiperSlide key={i} className={Classes.swiperElem}>
              <SmallRowSkeleton height="89vh" width="22rem" />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Swiper
          slidesPerView={3}
          spaceBetween={15}
          freeMode
          pagination={{ clickable: true }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {pipelines.map((pipeline) => {
            const decks = getDecksFor(pipeline._id);
            const pipelineId = pipeline._id;
            const { isEditing, title } = pipelineEdits[pipelineId] || {};

            return (
              <SwiperSlide key={pipelineId} className={Classes.swiperElem}>
                <div className={Classes.card}>
                  <div className='flexBetween mb10' >
                    <div className="flexAlign10" style={{ height: '2rem' }}>
                      {isEditing ? (
                        <>
                          <div className="flexAlign7">
                            <div className={Classes.searcCon} >
                              <input
                                placeholder="Update title"
                                value={title}
                                onChange={(e) =>
                                  setPipelineEdits((prev) => ({
                                    ...prev,
                                    [pipelineId]: { isEditing: true, title: e.target.value },
                                  }))
                                }
                              />
                            </div>
                            <div className="flexAlign7">
                              <div
                                onClick={() => {
                                  handleSaveTitle(pipelineId, title);
                                  // Turn off editing
                                  setPipelineEdits((prev) => ({
                                    ...prev,
                                    [pipelineId]: { isEditing: false, title },
                                  }));
                                }}
                                style={{ marginRight: '6px' }}
                              >
                                <SaveIcon />
                              </div>
                              <div
                                onClick={() =>
                                  setPipelineEdits((prev) => ({
                                    ...prev,
                                    [pipelineId]: { isEditing: false, title: prev[pipelineId].title },
                                  }))
                                }
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h5>{title}</h5>
                          <div
                            onClick={() =>
                              setPipelineEdits((prev) => ({
                                ...prev,
                                [pipelineId]: { isEditing: true, title: prev[pipelineId].title },
                              }))
                            }
                          >
                            <EditIcon />
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                    <StageMenu
  pipelines={pipelines}
  currentPipelineId={pipelineId}
  setdecisionLoading={setdecisionLoading}
  setLoading={setLoading}
/>
                    </div>
                  </div>

                  <div className={Classes.cardInner}>
                    {decks && decks.length > 0 ? (
                      decks.map((deckRef) => (
                        <PipelineCard
                          key={deckRef._id}
                          data={deckRef}
                          pipelineName={title}
                          pipelineId={pipelineId}
                          pipelines={pipelines}
                          setLoading={setLoading}
                          setdecisionLoading={setdecisionLoading}
                          setPopupData={setPopupData}
                          setopenSeeMorePopup={setopenSeeMorePopup}
                        />
                      ))
                    ) : (
                      <h5>No companies in this stage.</h5>
                    )}

                    {decisionLoading && (
                      <div className={Classes.loadingPopup}>
                        <h5>Loading...</h5>
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
}
