
import React, { useEffect, useState } from 'react';
import backend from '../../../../api';
import Classes from '../../Pipeline.module.css';
import { useSelector } from 'react-redux';
import PipelineMenu from './PipelineMenu';


export default function PipelineCard({
    setLoading,
    data,
    setdecisionLoading,
    setPopupData,
    setopenSeeMorePopup,
    pipelineName,
    pipelineId,
    pipelines
}) {
  const onBoarding = useSelector((state) => state.companyInfo.info);
  const companyId = onBoarding?._id;
  const activeScoringCriteria = onBoarding?.scoringCriteria?.filter((criteria) => criteria.status);
  const { user } = useSelector((state) => state.auth);

  const [companyUsers, setCompanyUsers] = useState();

  // Function to get removed users by deckId from localStorage
  const getRemovedUsersByDeck = (deckId) => {
    const storedData = JSON.parse(localStorage.getItem('removedUsers')) || {};
    return storedData[deckId] || [];
  };

  const [removedUsers, setRemovedUsers] = useState(getRemovedUsersByDeck(data?.deck?._id));
  const userId = user._id;

  // For opening See More popup
  function handlePopupData(deck) {
    setPopupData(deck);
    setopenSeeMorePopup(true);
  }

  // Format date
  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  // Weighted score calculations
  const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;
  const scores = {
    deckStructure: parseFloat(data?.deck?.deckStructureScoreMatch) || 0,
    marketOpportunity: parseFloat(data?.deck?.marketPotentialScoreMatch) || 0,
    marketingSales: parseFloat(data?.deck?.marketingSalesScoreMatch) || 0,
    businessModel: parseFloat(data?.deck?.businessModelScoreMatch) || 0,
    competitiveLandscape: parseFloat(data?.deck?.competitiveLandscapeScoreMatch) || 0,
    team: parseFloat(data?.deck?.teamScoreMatch) || 0,
    traction: parseFloat(data?.deck?.tractionScoreMatch) || 0,
  };

  let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
    return acc + scores[criteria.name] * weight;
  }, 0);
  totalScore = +(totalScore?.toFixed(2));

  const TotalReviewsDone = data?.deck?.feedback.filter(
    (feedbackItem) => feedbackItem.score && feedbackItem.score !== ''
  ).length;

  const IsAdminRatingDone = data?.deck?.feedback.filter(
    (feedbackItem) => feedbackItem.userId && feedbackItem?.userId?._id === userId
  );

  const totalComments =
    (data?.deck?.AdminComments ? data?.deck?.AdminComments.length : 0) +
    (data?.deck?.feedback
      ? data?.deck.feedback.filter((feedbackItem) => feedbackItem.comment && feedbackItem.comment.trim() !== '')
          .length
      : 0);

  // Fetch sub-admins from your backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await backend.get(`api/user/all`);
        if (response?.data) {
          const filteredUsers = response?.data?.data?.filter(
            (user) =>
              user?.companyId?.toString() === companyId?.toString() && user?.companySubAdmin === true
          );
          const visibleUsers = filteredUsers.filter((user) => !removedUsers.includes(user._id));
          setCompanyUsers(visibleUsers);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUsers();
  }, [companyId, removedUsers, data?.deck?._id]);

  // Count how many sub-admins have not left feedback
  const countUsersWithoutFeedback =
    companyUsers?.filter(
      (user) =>
        !data?.deck?.feedback?.some(
          (feedback) => String(feedback?.userId?._id) === String(user._id)
        )
    ).length ?? 0;

  // Time in stage
  function getElapsedTime(pastDate) {
    const past = new Date(pastDate);
    const now = new Date();
    const diffMs = now - past;
    if (diffMs < 0) {
      return 'In the future';
    }
    const msInMinute = 1000 * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;

    const elapsedDays = Math.floor(diffMs / msInDay);
    const elapsedHours = Math.floor((diffMs % msInDay) / msInHour);
    const elapsedMinutes = Math.floor((diffMs % msInHour) / msInMinute);

    if (elapsedDays >= 1) {
      return `${elapsedDays} day${elapsedDays === 1 ? '' : 's'}`;
    } else if (elapsedHours >= 1) {
      return `${elapsedHours} hour${elapsedHours === 1 ? '' : 's'}`;
    } else {
      return `${elapsedMinutes} minute${elapsedMinutes === 1 ? '' : 's'}`;
    }
  }

  return (
    <div className={Classes.cardBox}>
      <div className={Classes.flexBetween}>
        <div className={Classes.flexAlign7}>
          <h5 className={Classes.titleMini2} style={{ color: onBoarding?.color }}>
            {data?.deck?.userId?.companyName}
          </h5>
        </div>
        <h5 className={Classes.textMiniLight}>
          {getElapsedTime(data?.updatedAt)} in this stage
        </h5>
        {/* 
          Pass your entire pipelines array + the current pipeline info 
          to the PipelineMenu for dynamic dropdown 
        */}
        <PipelineMenu
        data={data}
        pipelines={pipelines}
        currentPipelineName={pipelineName}
        currentPipelineId={pipelineId}
        setdecisionLoading={setdecisionLoading}
        setLoading={setLoading}
      />
      </div>
      <hr className={Classes.line} />
      <div className={`${Classes.flexBetween} ${Classes.mb10}`}>
        <h5 className={Classes.titleMini}>Entered pipeline</h5>
        <h5 className={Classes.textLight}>{formatDate(data?.createdAt)}</h5>
      </div>
      <div className={Classes.flexBetween}>
        <h5 className={Classes.titleMini}>Entered current stage</h5>
        <h5 className={Classes.textLight}>{formatDate(data?.updatedAt)}</h5>
      </div>
      <hr className={Classes.line} />
      <h5 className={Classes.titleMini}>Review</h5>
      <div className={`${Classes.flexBetween} ${Classes.mb10} mt10`}>
        <div>
          <h5 className={Classes.textMiniLight}>{countUsersWithoutFeedback} review pending</h5>
          <h5 className={Classes.textMiniLight}>{TotalReviewsDone} reviews done</h5>
        </div>
        {IsAdminRatingDone ? (
          <div>
            <h5 className={Classes.scoreText} onClick={() => handlePopupData(data?.deck)}>
              {/* Show average numeric feedback score */}
              {(() => {
                const validFeedbacks = data?.deck?.feedback.filter(
                  (f) => typeof f.score === 'number'
                );
                if (!validFeedbacks.length) return 0;
                const avg = validFeedbacks.reduce((acc, f) => acc + f.score, 0) / validFeedbacks.length;
                return avg.toFixed(1);
              })()}{' '}
              &gt;
            </h5>
          </div>
        ) : (
          <div>
            <button
              className={Classes.rateBtn}
              style={{ background: onBoarding?.color ? onBoarding?.color : 'black' }}
              onClick={() => handlePopupData(data?.deck)}
            >
              <h5>Rate this startup</h5>
            </button>
          </div>
        )}
      </div>
      <hr className={Classes.line} />
      <h5 className={Classes.titleMini}>Comments</h5>
      {totalComments > 0 ? (
        <div className={`${Classes.flexAlign7} mt10`}>
          <img src="/icons/commentIcon.png" alt="comment" style={{ height: '18px' }} />
          <h5 className={Classes.textLight}>{totalComments}</h5>
        </div>
      ) : (
        <h5 className={Classes.textMiniLight}>No comments yet</h5>
      )}
    </div>
  );
}
