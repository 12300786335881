// src/components/Pipeline/Components/StageMenu.jsx

import React, { useState, useEffect, useRef } from 'react';
import backend from '../../../../api';
import Classes from '../../Pipeline.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { updateDeckInfo } from '../../../../redux-store/auth/actions';

const Eclipse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="25px" fill="black" viewBox="0 0 448 512">
    {/* SVG Path */}
    <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
  </svg>
);

export default function StageMenu({
  data,
  pipelines,
  currentPipelineName,
  currentPipelineId,
  setdecisionLoading,
  setLoading
}) {
  const [dropDown, setdropDown] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handler for deleting a stage
  const handleDeleteStage = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this stage? This will remove all decks from this stage."
    );
    if (!confirmDelete) return;
    setLoading(true);
    try {
      const response = await backend.delete(`/pipelines/${currentPipelineId}`);
      if (response.status === 200) {
        // Optionally update the redux store or refetch data
        await dispatch(updateDeckInfo(response?.data));
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting stage:", error);
    } finally {
      setLoading(false);
      setdropDown(false);
    }
  };

  return (
    <div className={Classes.dropDown}>
      <div
        className={Classes.dropDownHead}
        onClick={() => setdropDown(!dropDown)}
        ref={dropdownRef}
      >
        <Eclipse />
      </div>

      <AnimatePresence>
        {dropDown && (
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ duration: 0.15 }}
            className={Classes.dropDownBody}
          >
            <div className={Classes.ddPointerCon}>
              <div className={Classes.dropDownPointer}></div>
            </div>
            <div className={Classes.dropDownBodyInner}>
              <h5 className={Classes.dropDownMiniTitle}>Stage Options {'->'}</h5>
              <React.Fragment>
                <div
                  className={Classes.dropDownElem}
                  style={{ padding: '5px 10px', cursor: 'pointer' }}
                  onClick={handleDeleteStage}
                >
                  <h5>Delete Stage</h5>
                </div>
              </React.Fragment>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <input
        type="hidden"
        value={currentPipelineName}
        aria-label="Without label"
        readOnly
      />
    </div>
  );
}
